import React, { useContext, useState  } from "react"
import Layout from "../../components/layout"
import NullIf from "../../services/NullIf"

import Img from "gatsby-image"
import r from "../../services/Router"
import t from '../../services/GetTranslationValue'
import { graphql, Link } from "gatsby"
import styles from "./item.module.scss"
import ThemeContext from '../../context/ThemeContext'
import SEO from "../../components/seo"
import { Button } from "@material-ui/core"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import CasinoHelper from "../../services/casino-bonus-helpers"
import MainSearch from "../../components/main-search/main-search"
import FreespinsSearch from "../../components/main-search/freespins-search"
import RichText from "../../components/text-helpers/rich-text"
import GameCards from "../../components/game-cards/game-cards"
import { getFilterTranslationsByName } from "../../services/preset-filters/get-all-filters"
import localize from "../../components/localize"
import { localizeItem } from "../../graphql-assistant/createLocaleTextGetter"
import BlockContent from "../../components/text-helpers/portable-text"
import ContentWrapper from "../../components/text-helpers/content-wrapper"

const Content = ({ bonuses, bonusType, locale, isFreespins, translations }) => {

  const filters = getFilterTranslationsByName(translations, [ "headingfilter-freespins", "headingfilter-newcasinos", "headingfilter-latest-freespins" ])
  return (
    <div>
      { isFreespins ?
        <FreespinsSearch bonuses={bonuses} headerFilters={filters} locale={locale} />
        : <MainSearch locale={locale} bonuses={bonuses}  forceSpecificBonusType={bonusType} /> }
    </div>
  )
}

const CasinoBonusItem = ({ pageContext, location, data }) => {

  const [showItems, setShowItems] = useState(12);

  let site = useContext(ThemeContext)
  let bonuses = site.bonuses
  let loading = site.loading;
  let locale = site.locale;
  let translations = site.translations;
  let setBonuses = site.setBonuses;

  const newFreespinCards = () => {
    let promotedProviders = data.allSanityGenericLandingPage.edges[0].node.gameProviderRanking

    let { edges } = data.allSanityGameProvider
    const games = data.allSanityGame.nodes;

    let nodes = edges.map(x => x.node).sort((a, b) => {
      const aGames = games.filter(y => y.gameProvider.name === a.name).length
      const bGames = games.filter(y => y.gameProvider.name === b.name).length
      if (bGames < aGames)
        return 1;
      if (bGames > aGames)
        return -1;
      if (b.name < a.name)
        return 1;
      if (b.name > a.name)
        return -1;
      return 0;
    }).reverse();

    promotedProviders.slice().reverse().forEach(node => {
      nodes = [...nodes.filter(item => item.name === node.name),
      ...nodes.filter(item => item.name !== node.name)];
    })


    return (
      <div className={`${styles.providerFreespinCardsContainer}`}>
        {nodes && nodes.slice(0, showItems).map(x => (
          <div className={`${styles.providerFreespinCard}`}>
            <p className={`${styles.name}`}><span>{x.name}</span></p>
            <div className={`${styles.logo}`}>
              <Link to={r.get(locale, translations, `providerFreespins`, x.name)}>
                {x.largeIcon && x.largeIcon.asset && x.largeIcon.asset.fluid && (
                  <Img
                    alt={x.name}
                    fluid={x.largeIcon.asset.fluid}
                    className={`${styles.logoImage}`}
                  >
                    {" "}
                    {x.name}{" "}
                  </Img>
                )}
              </Link>
            </div>
            <div className={`${styles.details}`}>
              <p>
                <label>{t.get(translations, 'gp-number-of-games')}: </label>
                <span>{games.filter(y => y.gameProvider.name === x.name).length}</span>
              </p>
            </div>
            <div className={`${styles.button}`}>
              <Link to={r.get(locale, translations, `providerFreespins`, x.name)}>
                <Button
                  variant="contained"
                  color="primary"
                >
                  {x.name} {t.get(translations, 'gp-view-casinos')}
                </Button>
              </Link>
            </div>
          </div>
        ))}
        {nodes.length > showItems &&
          <div className={`${styles.viewMoreButton}`}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowItems(showItems + 12)}
            >
              {t.get(translations, 'show-more')}
            </Button>
          </div>
        }
      </div>
    )
  }

  let { bonusType, alternateLocaleSlugs } = pageContext

  let pageData = data.sanityGenericLandingPage
  let id = data.sanityBonusType._id;
  let bonusTypeData = data.sanityBonusType;
  bonuses = bonuses
    .filter(x => NullIf.isNotNullOrEmpty(x))
    .filter(x => NullIf.isNotNullOrEmpty(x.bonuses))
    .filter(x => NullIf.isNotNullOrEmpty(x.logo))

  const isFreeSpins = CasinoHelper.IsFreespinNoDepositBonus(id);

  return (
    <Layout breadcrumbNameOverride={bonusTypeData.breadcrumbName} silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location} title={pageData.seoTitle} bonuses={bonuses}
         description={pageData.seoMeta} alternateSlug={alternateLocaleSlugs} />
      <ContentWrapper
        title={bonusTypeData.h1Tag}
        header={bonusTypeData._rawHeaderText}
        body={bonusTypeData._rawCombinedBody}
        footer={bonusTypeData._rawFooterText}
        specialContent={newFreespinCards()} headerClassName={`gambleGeneralWrapper ${styles.casinoBonusPageHeader}`} bodyClassName={`gambleGeneralWrapper ${styles.casinoBonusPageBody}`}>
        <Content bonuses={bonuses} translations={translations} isFreespins={isFreeSpins} locale={locale} bonusType={id}/>
        {pageData._rawBodyBreakerText ?  <BlockContent cnInternal={`gambleGeneralWrapper ${styles.casinoBonusPageBody}`} blocks={pageData._rawBodyBreakerText}/> : ''}
        {pageData.promotedSlots && <div>
          <GameCards games={pageData.promotedSlots} translations={translations} locale={locale} />
        </div>}
      </ContentWrapper>
    </Layout>
  )
}

export default localize(CasinoBonusItem)

export const pageQuery = graphql`
  query BonusTypesQuery($bonusType: String!) {
    sanitySilo (_id: {eq: "3aacbadb-2f40-40ba-bcb8-f6deeb25edb4"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityBonusType (_id: { eq: $bonusType }) {
        _id
        breadcrumbName {
            ...LocaleString
        }
        name {
            ...LocaleString
        }
        h1Tag {
            ...LocaleString
        }
        seoMeta{
            ...LocaleString
        }
        seoTitle{
            ...LocaleString
        }
        _rawCombinedBody(resolveReferences: {maxDepth: 10})
        _rawFooterText(resolveReferences: {maxDepth: 10})
        _rawHeaderText(resolveReferences: {maxDepth: 10})
    }
    sanityGenericLandingPage(
      name: { eq: "Ilman talletusta" }
    ) {
      ...GenericLanding
    }

    allSanityGameProvider {
      edges {
        node {
          name
          largeIcon {
            asset {
                fluid {
                    ...GatsbySanityImageFluid
                }
            }
          }
        }
      }
    }
    allSanityGame {
        nodes {
            gameProvider {
                name
            }
        }
    }

    allSanityGenericLandingPage(filter: { name: { eq: "bonus-type-landing" } }) {
      edges {
        node {
          gameProviderRanking {
            name
          }
        }
      }
    }

  }
`
