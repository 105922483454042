import React, { useContext, useState } from "react"
import ThemeContext from "../../context/ThemeContext"
import Img from "gatsby-image"
import r from "../../services/Router"
import { graphql, Link, useStaticQuery } from "gatsby"
import t from '../../services/GetTranslationValue'
import NullIf from "../../services/NullIf"
import { Button } from "@material-ui/core"
import styles from "./game-cards.module.scss"

const GameCards = ({ games, provider, translations, locale, isPage }) => {
  const data = useStaticQuery(graphql`
    {
      defaultImage: file(relativePath: { eq: "Slot-placeholder-B.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }`)
  const site = useContext(ThemeContext)
  const bonus = site.bonuses

  const getOfferCountForCasino = (casinos, slotName) => {
    let numberOfOffers = 0;
    casinos.forEach(x => {
      x.bonuses.forEach(y => {
        if (y.relatedGames && y.relatedGames.some(z => z.name === slotName)) {
          numberOfOffers++;
        }
      })
    })

    return numberOfOffers;
  };

  const getCasinosOfferingSlot = (casinos, item) => casinos
    .filter(x => NullIf.isNotNullOrEmpty(x.bonuses))
    .filter(x => {
      let games = [].concat.apply([], x.bonuses)
      if (!NullIf.isNotNullOrEmpty(games)) return false
      // we have an array of casino bonuses now
      games = games.filter(y => NullIf.isNotNullOrEmpty(y.relatedGames))

      return games.some(y =>
        y.relatedGames.some(
          z => NullIf.isNotNullOrEmpty(z.name) && z.name === item
        )
      )
    });

  const casinosInfoData = (casinos, item) => {
    const casinosWithSlot = getCasinosOfferingSlot(casinos, item);
    const casinosOffersCount = getOfferCountForCasino(casinosWithSlot, item);

    return <div className={`${styles.details}`}>
      <p>
        <label>{t.get(translations, 'gp-number-of-casinos')}: </label>
        <span>{casinosWithSlot.length}</span>
      </p>
      <p>
        <label>{t.get(translations, 'games-number-of-free-spin-offers')}: </label>
        <span>{casinosOffersCount}</span>
      </p>
    </div>
  }

  const [showItems, setShowItems] = useState(12);
  return (
    NullIf.isNotNullOrEmpty(games) && games.length > 0 &&
    <div className={`${styles.gameCardsContainer}`}>
      { isPage && <h1>{t.get(translations, 'games-title-part-one')} {provider} {t.get(translations, 'games-title-part-two')}</h1> }
      { !isPage && <h2>{t.get(translations, 'games-title-part-one')} {provider} {t.get(translations, 'games-title-part-two')}</h2>}
      {games && games.filter(x => getCasinosOfferingSlot(bonus, x.name).length > 0).sort((a,b) => {
        const aSlot =getCasinosOfferingSlot(bonus, a.name).length;
        const bSlot = getCasinosOfferingSlot(bonus, b.name).length;

        if (aSlot < bSlot)
        return 1;
        if (aSlot > bSlot)
          return -1;

        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;}
      ).slice(0, showItems).map(x => (
        <div className={`${styles.gameCard}`}>
          <p className={`${styles.name}`}><span>{x.name}</span></p>
          <div className={`${styles.logo}`}>
            <Link to={r.get(locale, translations, `slotBonus`, x.name)}>
              {(
                <Img
                  alt={x.name}
                  fluid={(x.logoImage && x.logoImage.asset) ? x.logoImage.asset.fluid : data.defaultImage.childImageSharp.fluid}
                  className={`${styles.logoImage}`}
                >
                  {" "}
                  {x.name}{" "}
                </Img>
              )}
            </Link>
          </div>
          {casinosInfoData(bonus, x.name)}

          <div className={`${styles.button}`}>
            <Link to={r.get(locale, translations, "slotBonus", x.name)}>
              <Button
                variant="contained"
                color="primary"
              >
                {t.get(translations, 'gp-view-casinos')}
              </Button>
            </Link>
          </div>
        </div>
      ))}
      {games.length > showItems &&
        <div className={`${styles.viewMoreButton}`}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowItems(showItems + 12)}
          >
            {t.get(translations, 'show-more')}
          </Button>
        </div>
      }
    </div>
  )
}

export default GameCards